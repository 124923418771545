import ApiService from "@/core/services/api.service";
import { orderBy, filter } from "lodash";

// action types
export const GET_LISTING = "queryListing";
export const UPDATE_TABLE = "updateTable";
export const BULK_ACTION = "bulkAction";
export const BEFORE_DESTROY = "beforeDestroy";

// mutation types
export const SET_STATUS = "setStatus";
export const SET_FILTER = "setFilter";
export const SET_ACTION = "setAction";
export const SET_BULK_ACTION = "setBulkAction";
export const SET_TBODY = "setTbody";
export const SET_THEAD = "setThead";
export const SET_CURRENT_PAGE = "setCurrentPage";
export const SET_TOTAL_PAGE = "setTotalPage";
export const SET_SELECTED_ROWS = "setSelectedRows";
export const SET_SEARCH_MODEL = "setSearchModel";
export const SET_SEARCH_TITLE = "setSearchTitle";
export const SET_SHOWING_STRING = "setShowingString";
export const SET_ERROR = "setError";
export const SET_SEARCH_BAR = "setSearchBar";

const state = {
	errors: [],
	action: [],
	bulk_action: [],
	status: [],
	filter: [],
	tbody: [],
	thead: [],
	selected: [],
	current_page: 1,
	total_page: 0,
	showing_string: null,
	search_enabled: false,
	search_title: null,
	search_model: null,
};

const getters = {
	tbody(state) {
		return state.tbody || [];
	},
	searchEnabled(state) {
		return state.search_enabled || false;
	},
	searchTitle(state) {
		return state.search_title || null;
	},
	listingSearch(state) {
		return state.search_model || null;
	},
	listingStatus(state) {
		return state.status || [];
	},
	listingFilter(state) {
		return state.filter || [];
	},
	listingAction(state) {
		return state.action || [];
	},
	listingBulkAction(state) {
		return state.bulk_action || [];
	},
	currentPage(state) {
		return state.current_page || 1;
	},
	showingString(state) {
		return state.showing_string || null;
	},
	totalPage(state) {
		return state.total_page || 0;
	},
	sortedThead(state) {
		const result = orderBy(state.thead, ["order"], ["asc"]);
		return result ? filter(result, { visible: true }) : [];
	},
	theadShow(state) {
		const result = orderBy(state.thead, ["order"], ["asc"]);
		return result ? filter(result, { visible: true }).map((col) => col.key) : [];
	},
	thead(state) {
		return state.thead || [];
	},
	selected(state) {
		return state.selected || [];
	},
};

const actions = {
	[BEFORE_DESTROY](context) {
		context.commit(SET_ACTION, []);
		context.commit(SET_BULK_ACTION, []);
		context.commit(SET_STATUS, []);
		context.commit(SET_FILTER, []);
		context.commit(SET_TBODY, []);
		context.commit(SET_THEAD, []);
		context.commit(SET_ERROR, []);
		context.commit(SET_CURRENT_PAGE, 1);
		context.commit(SET_TOTAL_PAGE, 0);
		context.commit(SET_SHOWING_STRING, null);
		context.commit(SET_SEARCH_BAR, false);
		context.commit(SET_SEARCH_TITLE, null);
	},
	[GET_LISTING](context, { url, filter }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.query(url, filter)
				.then(({ data }) => {
					context.commit(SET_ACTION, data.action);
					context.commit(SET_BULK_ACTION, data.bulk_action);
					context.commit(SET_CURRENT_PAGE, data.current_page);
					context.commit(SET_TOTAL_PAGE, data.total_page);
					context.commit(SET_SHOWING_STRING, data.showing_string);
					context.commit(SET_STATUS, data.status);
					context.commit(SET_FILTER, data.filter);
					context.commit(SET_TBODY, data.tbody);
					context.commit(SET_THEAD, data.thead);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.message);
					reject(response);
				});
		});
	},
	[UPDATE_TABLE](context, { type, params }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(`table/${type}`, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.message);
					reject(response);
				});
		});
	},
	[BULK_ACTION](context, { type, url, selected }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(url, { action: type, selected })
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.message);
					reject(response);
				});
		});
	},
	[SET_SEARCH_BAR](state, payload) {
		state.commit(SET_SEARCH_BAR, payload);
	},
	[SET_SEARCH_TITLE](state, payload) {
		state.commit(SET_SEARCH_TITLE, payload);
	},
	[SET_SEARCH_MODEL](state, payload) {
		state.commit(SET_SEARCH_MODEL, payload);
	},
};

const mutations = {
	[SET_ACTION](state, data) {
		state.action = data;
	},
	[SET_BULK_ACTION](state, data) {
		state.bulk_action = data;
	},
	[SET_CURRENT_PAGE](state, data) {
		state.current_page = data;
	},
	[SET_TOTAL_PAGE](state, data) {
		state.total_page = data;
	},
	[SET_SHOWING_STRING](state, data) {
		state.showing_string = data;
	},
	[SET_STATUS](state, data) {
		state.status = data;
	},
	[SET_FILTER](state, data) {
		state.filter = data;
	},
	[SET_TBODY](state, data) {
		state.tbody = data;
	},
	[SET_THEAD](state, data) {
		state.thead = data;
	},
	[SET_SELECTED_ROWS](state, rows) {
		state.selected = rows;
	},
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_SEARCH_TITLE](state, title) {
		state.search_title = title;
	},
	[SET_SEARCH_BAR](state, status) {
		state.search_enabled = status;
	},
	[SET_SEARCH_MODEL](state, model) {
		state.search_model = model;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
